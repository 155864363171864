import { Button, Input, message, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddRawMaterial.module.scss";

const CustomerSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Enter raw material name"),
  unit: Yup.string().required("Choose unit"),
  price: Yup.number().required("Enter price"),
});

function AddRawMaterial({ show, setShow, getAll }) {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const addPost = (values) => {
    const data = JSON.stringify(values);
    var config = {
      method: "post",
      url: `${BASE_URL}/api/raw-materials`,
      headers: {
        "x-auth-token": token(),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        setShow(false);
        getAll();
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };
  return (
    <Modal
      visible={show}
      onCancel={() => setShow(false)}
      header={null}
      footer={null}
      destroyOnClose
    >
      <div className={styles.AddRawMaterialWrapper}>
        <h5 className={styles.heading}>Add Raw Materials</h5>
        <Formik
          initialValues={{
            name: "",
            unit: "",
            price: "",
          }}
          validationSchema={CustomerSchema}
          onSubmit={(values) => {
            setLoading(true);
            addPost(values);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            values,
          }) => (
            <div className={styles.content}>
              <p className="mb-1">
                Raw Material Name<span className="text-danger">*</span>
              </p>
              <Input
                placeholder="Raw material name"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
              />
              {errors.name && touched.name ? (
                <pre className="text-danger">{errors.name}</pre>
              ) : null}
              <div className="d-flex justify-content-between mt-2">
                <div className="col-5">
                  <p className="mb-1">
                    Unit<span className="text-danger">*</span>
                  </p>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Unit"
                    optionFilterProp="children"
                    onChange={handleChange("unit")}
                    onBlur={handleBlur("unit")}
                    value={values.unit}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="nos">Numbers</Option>
                    <Option value="kilo">Kilogram</Option>
                    <Option value="litre">Litre</Option>
                  </Select>
                  {errors.unit && touched.unit ? (
                    <pre className="text-danger">{errors.unit}</pre>
                  ) : null}
                </div>
                <div />
                <div className="col-5">
                  <p className="mb-1">
                    Unit Price<span className="text-danger">*</span>
                  </p>
                  <Input
                    placeholder="Price"
                    onChange={handleChange("price")}
                    onBlur={handleBlur("price")}
                    value={values.price}
                    type="number"
                  />
                  {errors.price && touched.price ? (
                    <pre className="text-danger">{errors.price}</pre>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <Button
                  className={styles.cancel}
                  onClick={() => {
                    handleReset();
                    setShow(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.submit}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default AddRawMaterial;
