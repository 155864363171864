/* eslint-disable react/prop-types */
import {
  CloseCircleFilled,
  CloseOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Upload,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { getUrlExtension, token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./EditProduct.module.scss";

function EditProduct({ detail, setIsModalVisible, getProducts }) {
  const [imageUrl, setImageUrl] = useState([]);
  const onImageChange = (e) => {
    setImageUrl(e?.fileList);
  };

  useEffect(() => {
    if (detail?.imageArr && imageUrl?.length === 0) {
      setImageUrl(
        detail?.imageArr?.map((item) => ({ thumbUrl: BASE_URL + "/" + item }))
      );
    }
  }, [detail]);

  const { TextArea } = Input;
  const { Option } = Select;

  const ProductSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(150, "Too Long!")
      .required("Please enter the product name"),
    isfeatured: Yup.boolean(),
    status: Yup.boolean(),
    description: Yup.string()
      .required("Please enter product description")
      .min(2, "Too Short!")
      .max(150, "Too Long!"),
    overhead: Yup.number("Enter over head cost %").required(
      "Enter over head cost"
    ),
    othercosts: Yup.number("Enter other costs").required("Enter other cost"),
    category: Yup.string().required("Please select the category"),
    wholesaleprice: Yup.number("Enter whole sale price").required(
      "Enter whole sale price"
    ),
    retailprice:
      Yup.number("Enter retail price").required("Enter retail price"),
    pieceweight: Yup.number("Enter piece weight in gram").required(
      "Enter piece weight in gram"
    ),
    yieldpkts: Yup.number("Enter no. of pieces in a packet"),
  });

  const [result, setresult] = useState([]);
  const fetchCategory = () => {
    let config = {
      method: "get",
      url: `${BASE_URL}/api/categories`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setresult(response?.data?.data);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (result?.length === 0) fetchCategory();
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleClear = (clear) => {
    clear();
    setImageUrl([]);
    setIsModalVisible(null);
  };

  const onSubmit = (submit) => {
    if (imageUrl?.length > 0) {
      submit();
    } else {
      message.error("Please attach images to continue");
    }
  };

  const [loading, setLoading] = useState(false);
  const [ingredientTable, setIngredient] = useState(detail?.ingredients);
  const [searchArr, setSearch] = useState([]);

  const columns = [
    {
      title: "Ingredients",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit Name",
      key: "unit",
      render: () => <span>gram</span>,
    },
    {
      title: "Measure (gram)",
      key: "qty",
      render: (data1, record, index) => (
        <Input
          type={"number"}
          contentEditable
          // defaultValue={ingredientTable?.[index]?.qty}
          value={data1.qty}
          min={0}
          onBlur={(e) => handleQty(e, record, data1, index)}
          onChange={(e) => handleChangeQty(e, record, data1, index)}
        />
      ),
      width: "20%",
    },
    {
      title: "Cost/Unit",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Sub Total",
      dataIndex: "subtotal",
      key: "subtotal",
    },
    {
      title: "",
      key: "total",
      render: (data) => (
        <CloseCircleFilled
          className="text-danger"
          onClick={() => handleRemove(data?.name)}
        />
      ),
    },
  ];

  const handleQty = (e, data1, record, index) => {
    if (Number(e.target.value) > 0) {
      const key1 = "qty";
      const key2 = "subtotal";
      const newData = [...ingredientTable];
      newData[index][key1] = Number(e.target.value);
      newData[index][key2] = parseFloat(
        ((Number(e.target.value) * data1?.price) / 1000)?.toFixed(2)
      );
      setIngredient(newData);
    }
  };

  const handleChangeQty = (e, data1, record, index) => {
    const key1 = "qty";
    const key2 = "subtotal";
    const newData = [...ingredientTable];
    newData[index][key1] = Number(e.target.value);
    newData[index][key2] = parseFloat(
      ((Number(e.target.value) * data1?.price) / 1000)?.toFixed(2)
    );
    setIngredient(newData);
  };

  const doSearch = (keyword) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/search-ingredients?q=${keyword}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    if (keyword?.length > 1) {
      axios(config)
        .then(function (response) {
          setSearch(response?.data?.data);
        })
        .catch(function (error) {
          setLoading(false);
          message.error(error.data.message);
        });
    }
  };

  const handleRemove = (id) => {
    setIngredient(ingredientTable?.filter((item) => item?.name !== id));
  };
  const rawTotal = () => {
    let total = 0;
    ingredientTable?.forEach(
      (item) => (total = Number(total) + Number(item?.subtotal))
    );
    return parseFloat(total?.toFixed(2));
  };

  const handleIngredients = (item) => {
    const ingredient = searchArr?.find((x) => x?._id === item);
    const Arr = [...ingredientTable, ...[ingredient]];
    const rowData = Arr?.map((item, i) => ({
      key: i + 1,
      name: item?.name || item?.ingredients,
      unit: "gram",
      price: item?.price,
      qty: item?.qty,
      subtotal: parseFloat(
        ((item?.price * (item?.qty || 1)) / 1000).toFixed(2)
      ),
      _id: item?._id || item?.id,
    }));

    setIngredient(rowData);
  };

  const handleEdit = async (values) => {
    let FormData = require("form-data");
    let data = new FormData();

    const ingredients = ingredientTable?.map((item) => ({
      id: item?._id || item?.id,
      qty: item?.qty,
    }));

    const reqImg = imageUrl?.[0];
    await imageAppend(reqImg, data);
    data.append("name", values.name);
    data.append("description", values.description);
    data.append("category", values.category);
    data.append("ingredients", JSON.stringify(ingredients));
    data.append("overhead", values.overhead);
    data.append("othercosts", values.othercosts);
    data.append("overheadcost", (rawTotal() * values.overhead) / 100);
    data.append("isfeatured", values.isfeatured);
    data.append("status", values.status);
    data.append("wholesaleprice", values.wholesaleprice);
    data.append("retailprice", values.retailprice);
    data.append("pieceweight", values.pieceweight);
    data.append("yieldpkts", values.yieldpkts);

    let config = {
      method: "put",
      url: `${BASE_URL}/api/products/${detail?._id}`,
      headers: {
        "x-auth-token": token(),
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getProducts();
        setLoading(false);
        message.success(response?.data?.message);
        setIsModalVisible(null);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const imageAppend = async (reqImg, data) => {
    if (reqImg?.originFileObj) {
      await data.append("images", reqImg?.originFileObj);
    } else {
      const url = reqImg?.thumbUrl;
      const imgExt = getUrlExtension(url);
      const fileName = `myFile.${imgExt}`;
      await fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        await data.append("images", file);
      });
    }
  };

  const grandtotal = (values) => {
    const total =
      Number(rawTotal()) +
      Number(values.othercosts) +
      (Number(rawTotal()) * Number(values.overhead)) / 100;
    return parseFloat(total?.toFixed(2));
  };

  const totalWeight = () => {
    let weight = 0;
    ingredientTable?.forEach(
      (item) => (weight = Number(weight) + Number(item?.qty))
    );
    return weight;
  };

  const unitPrice = (num, values) => {
    const total = grandtotal(values) || 0;
    const price = parseFloat((total / Number(num)).toFixed(2));
    return price;
  };

  return (
    <div className={styles.EditProductWrapper}>
      <Formik
        initialValues={{
          name: detail?.name,
          description: detail?.description,
          category: detail?.categoryId,
          overhead: detail?.overhead,
          othercosts: detail?.othercosts,
          isfeatured: detail?.isfeatured,
          status: detail?.status,
          wholesaleprice: detail?.wholesaleprice,
          retailprice: detail?.retailprice,
          pieceweight: detail?.pieceweight,
          yieldpkts: detail?.yieldpkts || 0,
        }}
        validationSchema={ProductSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          setLoading(true);
          handleEdit(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          setFieldValue,
          values,
        }) => (
          <>
            <div className="mb-3">
              <p>
                Product Name<span className="text-danger">*</span>
              </p>
              <Input
                placeholder="Product name"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
              />{" "}
              {errors.name && touched.name ? (
                <pre className="text-danger">{errors.name}</pre>
              ) : null}
            </div>
            <div className="mb-3">
              <p>
                Description<span className="text-danger">*</span>
              </p>
              <TextArea
                rows={4}
                placeholder="Description"
                onChange={handleChange("description")}
                onBlur={handleBlur("description")}
                value={values.description}
              />
              {errors.description && touched.description ? (
                <pre className="text-danger">{errors.description}</pre>
              ) : null}
            </div>
            <div className="mb-3">
              <p>
                Category<span className="text-danger">*</span>
              </p>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Category"
                optionFilterProp="children"
                onChange={handleChange("category")}
                onBlur={handleBlur("category")}
                value={values.category}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {result?.map((item) => (
                  <Option key={item?._id} value={item?._id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>

              {errors.category && touched.category ? (
                <pre className="text-danger">{errors.category}</pre>
              ) : null}
            </div>
            <Row gutter="20" className="my-2">
              <Col span={8}>
                <Checkbox
                  defaultChecked
                  onChange={(e) =>
                    setFieldValue("isfeatured", e?.target?.checked)
                  }
                >
                  Is Featured?
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  defaultChecked
                  onChange={(e) => setFieldValue("status", e?.target?.checked)}
                >
                  Is Visible?
                </Checkbox>
              </Col>
            </Row>
            <p>Raw Materials</p>
            <div className={styles.rawContainer}>
              <div>
                {/* <Input
                    placeholder="Search ingredients, then hit enter to add"
                    suffix={
                      <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                    }
                  /> */}
                <Select
                  showSearch
                  style={{ width: "100%", marginBottom: 15 }}
                  placeholder="Search ingredients"
                  optionFilterProp="children"
                  onChange={handleIngredients}
                  onSearch={(e) => doSearch(e)}
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {searchArr?.map((item) => (
                    <Option value={item?._id} key={item?._id}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
                <Table
                  columns={columns}
                  dataSource={[...ingredientTable]}
                  pagination={false}
                  rowKey="_id"
                  footer={() => (
                    <Row className="w-100" justify="end">
                      <h6 className="mb-0">Total: AED {rawTotal()}</h6>
                    </Row>
                  )}
                />
              </div>
            </div>
            <Row align="top" gutter="20" className="mt-3">
              <Col span={6}>
                <p className="mb-1">Total Weight</p>
                <Input
                  placeholder="Weight in Kg"
                  onChange={handleChange("weight")}
                  onBlur={handleBlur("weight")}
                  value={(totalWeight() / 1000).toFixed(2)}
                  type="number"
                  disabled
                />
              </Col>{" "}
              <Col span={6}>
                <p className="mb-1">Weight Per Piece(g)</p>
                <Input
                  placeholder="Weight per piece (g)"
                  onChange={handleChange("pieceweight")}
                  onBlur={handleBlur("pieceweight")}
                  value={values.pieceweight}
                  type="number"
                />
                {errors.pieceweight && touched.pieceweight ? (
                  <pre className="text-danger">{errors.pieceweight}</pre>
                ) : null}
              </Col>{" "}
              <Col span={6} className="mb-2">
                <p className="mb-1">Pieces per packet</p>
                <Input
                  placeholder="No. of pieces in packets"
                  type="number"
                  value={values.yieldpkts}
                  onChange={handleChange("yieldpkts")}
                  onBlur={handleBlur("yieldpkts")}
                />
                {errors.yieldpkts && touched.yieldpkts ? (
                  <pre className="text-danger">{errors.yieldpkts}</pre>
                ) : null}
              </Col>
              <Col span={6} className="mb-2">
                <p className="mb-1">Yield Pieces</p>
                <Input
                  placeholder="Yield Pieces"
                  value={(totalWeight() / (values?.pieceweight || 1)).toFixed(
                    2
                  )}
                  disabled
                  // addonBefore="AED"
                  type="number"
                />
              </Col>
            </Row>

            <Row gutter="20" className="mt-3">
              <Col span={6}>
                <h5 className="text-bold">Price details</h5>
              </Col>
            </Row>
            <Row align="top" justify="space-between" gutter="20">
              <Col span={6}>
                <p className="mb-1">Over Head %</p>
                <Input
                  placeholder="Over Head %"
                  onChange={handleChange("overhead")}
                  onBlur={handleBlur("overhead")}
                  value={values.overhead}
                  type="number"
                />
                {errors.overhead && touched.overhead ? (
                  <pre className="text-danger">{errors.overhead}</pre>
                ) : null}
              </Col>
              <Col span={6}>
                <p className="mb-1">Other Cost</p>
                <Input
                  placeholder="Other cost"
                  onChange={handleChange("othercosts")}
                  onBlur={handleBlur("othercosts")}
                  value={values.othercosts}
                  addonBefore="AED"
                  type="number"
                />
                {errors.othercosts && touched.othercosts ? (
                  <pre className="text-danger">{errors.othercosts}</pre>
                ) : null}
              </Col>
              <Col span={6}>
                <p className="mb-1">Over Head Cost (AED)</p>
                <Input
                  type="number"
                  addonBefore="AED"
                  placeholder="Over Head Cost"
                  value={rawTotal() * (values.overhead / 100)}
                  disabled
                />
              </Col>
              <Col span={6}>
                <p className={styles.total} style={{ marginBottom: "3px" }}>
                  Total Cost (AED)
                </p>
                <div className={(styles.totalInput, "mb-0")}>
                  <h5>{`AED ${grandtotal(values)}`}</h5>
                </div>
              </Col>
            </Row>

            <Row align="top" gutter="20" className="mt-4">
              <Col span={6} className="mb-2">
                <p className="mb-1">Unit Price</p>
                <Input
                  placeholder="Unit price"
                  addonBefore="AED"
                  type="number"
                  disabled
                  value={unitPrice(
                    totalWeight() / (values?.pieceweight || 1),
                    values
                  )}
                />
              </Col>
              <Col span={6} className="mb-2">
                <p className="mb-1">Packet Price</p>
                <Input
                  placeholder="Packet price"
                  addonBefore="AED"
                  type="number"
                  disabled
                  value={(
                    unitPrice(
                      totalWeight() / (values?.pieceweight || 1),
                      values
                    ) * values.yieldpkts
                  )?.toFixed(2)}
                />
              </Col>
              <Col span={6} className="mb-2">
                <p className="mb-1">Whole Sale Price</p>
                <Input
                  placeholder="Whole sale price"
                  onChange={handleChange("wholesaleprice")}
                  onBlur={handleBlur("wholesaleprice")}
                  value={values.wholesaleprice}
                  addonBefore="AED"
                  type="number"
                />
                {errors.wholesaleprice && touched.wholesaleprice ? (
                  <pre className="text-danger">{errors.wholesaleprice}</pre>
                ) : null}
              </Col>
              <Col span={6} className="mb-2">
                <p className="mb-1">Retail Price</p>
                <Input
                  placeholder="Retail Price"
                  onChange={handleChange("retailprice")}
                  onBlur={handleBlur("retailprice")}
                  value={values.retailprice}
                  addonBefore="AED"
                  type="number"
                />
                {errors.retailprice && touched.retailprice ? (
                  <pre className="text-danger">{errors.retailprice}</pre>
                ) : null}
              </Col>
            </Row>

            <span className="d-flex align-items-center">
              <h5 className="text-bold mt-3">Upload Image</h5>
            </span>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={true}
              onChange={(e) => onImageChange(e)}
              accept="image/*"
              fileList={imageUrl}
              onSubmit={() => {}}
              maxCount={4}
              multiple={true}
            >
              {uploadButton}
            </Upload>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Space align="center">
                <Button
                  type="secondary"
                  icon={<CloseOutlined />}
                  size="large"
                  className="d-flex align-items-center"
                  onClick={() => handleClear(handleReset)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => onSubmit(handleSubmit)}
                  type="primary"
                  icon={<SaveOutlined />}
                  size="large"
                  loading={loading}
                  className="d-flex align-items-center"
                >
                  Save
                </Button>
              </Space>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

export default EditProduct;
