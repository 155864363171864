import { Breadcrumb, Col, message, Radio, Row, Table, Tag } from "antd";
import Search from "antd/lib/input/Search";
import axios from "axios";
import Topbar from "common/Topbar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./SuspendedSales.module.scss";

function SuspendedSales() {
  const history = useHistory();
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Sales</Breadcrumb.Item>
        <Breadcrumb.Item>Orders</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Orders</h3>
    </div>
  );

  const RightComponent = () => <div />;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);

  const getSales = (status) => {
    setLoading(true);
    const config = {
      method: "get",
      url:
        status === "all"
          ? `${BASE_URL}/api/orders`
          : `${BASE_URL}/api/orders?status=${status}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setResult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  useEffect(() => {
    getSales("all");
  }, []);

  const getOrderById = (id) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/orders/${id}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        history.push("/invoice", { data: response.data.data });
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const dataSource = result?.map((item) => {
    let product = "";
    item?.orderitems?.forEach((element) => {
      product = `${product}${element?.name},`;
    });
    return {
      id: item?.ordernumber,
      time: moment(new Date(item?.date)).format("DD/MM/YYYY"),
      customer: item?.user?.username,
      items: product,
      total: item?.nettotal,
      payment: item?.paymenttype,
      status: item?.status,
      discount: item?.salesdiscount,
      // ...item,
    };
  });

  // const menu = (data) => (
  //   <Menu>
  //     <Menu.Item key="0" onClick={() => getOrderById(data?.id)}>
  //       <p>View</p>
  //     </Menu.Item>
  //     {/* <Menu.Item key="1">
  //       <p>Delete</p>
  //     </Menu.Item> */}
  //   </Menu>
  // );

  const columns = [
    // {
    //   title: () => <Checkbox checked disabled />,
    //   key: "checkbox",
    //   width: "5%",
    //   render: () => <Checkbox />,
    // },
    {
      title: "Order No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      ellipsis: true,
    },
    {
      title: "Discount",
      key: "discount",
      render: (data) => <span>{`AED ${data?.discount}`}</span>,
    },
    {
      title: "Total",
      key: "total",
      render: (data) => <span>{`AED ${data?.total?.toFixed(2)}`}</span>,
    },
    {
      title: "Status",
      key: "receipt",
      render: (data) =>
        data?.status === "completed" ? (
          <Tag color="green">Completed</Tag>
        ) : data?.status === "pending" ? (
          <Tag color="red">Pending</Tag>
        ) : (
          <Tag color="gold">Preparing</Tag>
        ),
    },

    // {
    //   title: "",
    //   width: "5%",
    //   key: "action",
    //   render: (data) => (
    //     <Dropdown overlay={() => menu(data)} trigger={["click"]}>
    //       <div className="w-100 d-flex align-items-center justify-content-center">
    //         <MoreOutlined />
    //       </div>
    //     </Dropdown>
    //   ),
    // },
  ];

  return (
    <div className={styles.SuspendedSalesWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="container pt-3">
        <Row align="middle" justify="space-between" className="py-3">
          <Col>
            <Search
              placeholder="Order Number"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(val) => getOrderById(val)}
            />
          </Col>
          <Col>
            <Radio.Group
              defaultValue="all"
              buttonStyle="solid"
              onChange={(e) => {
                getSales(e.target.value);
              }}
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="pending">Pending</Radio.Button>
              <Radio.Button value="placed">Preparing</Radio.Button>
              <Radio.Button value="completed">Completed</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          onRow={(record) => {
            return {
              onClick: () => getOrderById(record?.id), // click row
            };
          }}
        />
      </div>
    </div>
  );
}

export default SuspendedSales;
