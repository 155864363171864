/* eslint-disable no-unused-vars */
import {
  DeleteFilled,
  DownOutlined,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
  Table,
} from "antd";
import axios from "axios";
import EditProduct from "common/EditProduct";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Product.module.scss";

function Product() {
  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(null);

  const showModal = (item) => {
    setIsModalVisible(item);
  };

  const getProducts = () => {
    let config = {
      method: "get",
      url: `${BASE_URL}/api/products?page=1&size=20`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setResult(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    getProducts();
    fetchCategory();
  }, []);

  const [categoryList, setCategoryList] = useState(null);

  const fetchCategory = () => {
    let config = {
      method: "get",
      url: `${BASE_URL}/api/categories`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setCategoryList(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const history = useHistory();
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Products</h3>
    </div>
  );
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => history.push("/add-products")}
    >
      Add Products
    </Button>
  );

  const columns = [
    {
      title: "SL No.",
      dataIndex: "id",
      key: "id",
      render: (text) => <p className="text-center">{text}</p>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <img src={src} alt="category-img" className={styles.productImg} />
      ),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "Price",
      key: "retailprice",
      dataIndex: "retailprice",
    },
    // {
    //   title: "Unit",
    //   key: "unit",
    //   dataIndex: "unit",
    // },
    // {
    //   title: "Stock",
    //   key: "stock",
    //   dataIndex: "stock",
    // },

    {
      title: "Status",
      key: "stock",
      render: (text) => {
        return (
          // <Dropdown overlay={menu} placement="bottomCenter" arrow>
          <Button>{text?.status ? "Visible" : "In Visible"}</Button>
          // </Dropdown>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Space size="middle">
          <EditFilled
            onClick={(e) => {
              e.stopPropagation();
              showModal(item);
            }}
          />
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={(e) => {
              e.stopPropagation();
              handleDelete(item?._id);
            }}
            onCancel={(e) => {
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled
              className="text-danger"
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
          {/* <MoreOutlined /> */}
        </Space>
      ),
    },
  ];

  const data = result?.map((item, i) => ({
    id: i + 1,
    image: BASE_URL + "/" + item?.imagekeys?.[0],
    name: item?.name,
    category: item?.category?.name,
    categoryId: item?.category?._id,
    price: item?.price?.toFixed(2),
    stock: item?.stockqty,
    discount: item?.discount,
    description: item?.description,
    vat: item?.vat > 0,
    status: item?.status,
    unit: item?.unit,
    _id: item?._id,
    imageArr: item?.imagekeys,
    ingredientcost: item?.ingredientcost,
    ingredients: item?.ingredients,
    othercosts: item?.othercosts,
    overhead: item?.overhead,
    overheadcost: item?.overheadcost,
    isfeatured: item?.isfeatured,
    yieldpkts: item?.yieldpkts,
    yieldpcs: item?.yieldpcs,
    wholesaleprice: item?.wholesaleprice,
    retailprice: item?.retailprice?.toFixed(2),
    weight: item?.weight,
    pieceweight: item?.pieceweight,
    code: item?.code,
    unitprice: item?.unitprice?.toFixed(2),
  }));

  const handleDelete = (id) => {
    let config = {
      method: "delete",
      url: `${BASE_URL}/api/products/${id}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        getProducts();
        setLoading(false);
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const menu = (data) => {
    return (
      <Menu onClick={handleMenuClick}>
        {data?.map((item) => (
          <Menu.Item key={item?._id}>{item?.name}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const [selectedCategory, setSelected] = useState({ name: "All", _id: "all" });

  function handleMenuClick(e) {
    const category = categoryList?.find((item) => item?._id === e?.key) || {
      name: "All",
      _id: "all",
    };
    setSelected(category);
  }

  function onChange(e) {
    const category = categoryList?.find(
      (item) => item?._id === e?.target?.value
    ) || {
      name: "All",
      _id: "all",
    };
    setSelected(category);
  }

  const { Search } = Input;
  const [searchData, setSearchData] = useState(null);
  const handleUserSearch = (text) => {
    if (text?.length === 0) {
      setSearchData(null);
      return;
    }
    setLoading(true);
    const config = {
      method: "get",
      url: `${BASE_URL}/api/search-product?q=${text}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);

        let resp =
          response?.data?.data?.map((item, i) => ({
            id: i + 1,
            image: BASE_URL + "/" + item?.imagekeys?.[0],
            name: item?.name,
            category: item?.category?.name,
            categoryId: item?.category?._id,
            price: item?.price?.toFixed(2),
            stock: item?.stockqty,
            discount: item?.discount,
            description: item?.description,
            vat: item?.vat > 0,
            status: item?.status,
            unit: item?.unit,
            _id: item?._id,
            imageArr: item?.imagekeys,
            ingredientcost: item?.ingredientcost,
            ingredients: item?.ingredients,
            othercosts: item?.othercosts,
            overhead: item?.overhead,
            overheadcost: item?.overheadcost,
            isfeatured: item?.isfeatured,
            yieldpkts: item?.yieldpkts,
            yieldpcs: item?.yieldpcs,
            wholesaleprice: item?.wholesaleprice,
            retailprice: item?.retailprice?.toFixed(2),
            weight: item?.weight,
            pieceweight: item?.pieceweight,
            code: item?.code,
            unitprice: item?.unitprice?.toFixed(2),
          })) || [];
        setSearchData(resp);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };
  return (
    <div className={styles.ProductWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <Row
        className="w-100 pt-3"
        justify="space-between"
        gutter={15}
        align="middle"
      >
        <Col span={12}>
          <div className="d-flex">
            <Search
              placeholder="Enter product name"
              enterButton="Search"
              size="large"
              onSearch={handleUserSearch}
              allowClear
            />
            <Button
              className="mx-3"
              size="large"
              onClick={() => setSearchData(null)}
            >
              Reset
            </Button>
          </div>
        </Col>

        <Col span={12}>
          <Radio.Group
            onChange={onChange}
            defaultValue={selectedCategory?._id}
            value={selectedCategory?._id}
            optionType="button"
            buttonStyle="solid"
            size="large"
          >
            {categoryList?.length > 0 &&
              [...[{ name: "All", _id: "all" }], ...categoryList]
                ?.slice(0, 4)
                ?.map((item) => (
                  <Radio.Button key={item?._id} value={item?._id}>
                    {item?.name}
                  </Radio.Button>
                ))}
            {categoryList?.length > 4 && (
              <Dropdown
                overlay={() =>
                  menu(categoryList?.slice(3, categoryList.length))
                }
              >
                <Button size="large">
                  More <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </Radio.Group>
        </Col>
      </Row>
      <div className="pt-3">
        <Table
          columns={columns}
          dataSource={
            searchData
              ? searchData
              : selectedCategory?._id === "all"
              ? data
              : data?.filter(
                  (item) => item?.category === selectedCategory?.name
                )
          }
          pagination={data?.length > 20}
          loading={isLoading}
          onRow={(record) => {
            return {
              onClick: () => history.push("product-detail", { record }), // click row
            };
          }}
        />
      </div>
      <Modal
        footer={null}
        title="Edit Product"
        visible={isModalVisible !== null}
        setIsModalVisible={setIsModalVisible}
        onCancel={() => setIsModalVisible(null)}
        isModalVisible={isModalVisible}
        destroyOnClose={true}
        width="1000px"
      >
        <EditProduct
          detail={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          getProducts={getProducts}
        />
      </Modal>
    </div>
  );
}

export default Product;
