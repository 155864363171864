/* eslint-disable react/prop-types */
import Wrapper from "common/Wrapper";
import React from "react";
import PrintProvider, { NoPrint } from "react-easy-print";
import { Redirect, Route } from "react-router-dom";
import { isLogin } from "utils";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <PrintProvider>
            <NoPrint>
              <Wrapper>
                <Component {...props} />
              </Wrapper>
            </NoPrint>
          </PrintProvider>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
