import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import { useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

// import styles from "./RawMaterialUpload.module.scss";

function RawMaterialUpload({ open, setUpload }) {
  const [file, setFile] = useState(null);

  const handleOk = () => {
    const data = new FormData();
    data.append("file", file?.[0]?.originFileObj);

    var config = {
      method: "post",
      url: `${BASE_URL}/api/import-materials`,
      headers: {
        "x-auth-token": token(),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });

    setUpload(false);
  };

  const handleCancel = () => {
    setUpload(false);
  };

  const handleChange = (data) => {
    setFile(data?.fileList);
  };

  const props = {
    action: () => {},
    onChange: handleChange,
    maxCount: 1,
    accept: ".xlsx, .xls, .csv",
  };
  return (
    <Modal
      title="Upload Raw material (Bulk)"
      visible={open}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div className="d-flex align-items-center justify-content-center w-100">
        <Upload {...props} fileList={file}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </div>
    </Modal>
  );
}

export default RawMaterialUpload;
