import styles from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={styles.NotFoundWrapper}>
      <h1>404 | PAGE NOT FOUND :( </h1>
    </div>
  );
}

export default NotFound;
