import "./App.scss";

import PrivateRoute from "common/PrivateRoute";
import PublicRoute from "common/PublicRoute";
import ScrollToTop from "common/ScrollToTop";
import AddCategory from "pages/AddCategory";
import AddProduct from "pages/AddProduct";
import Category from "pages/Category";
import ForgotPassword from "pages/ForgotPassword";
import Invoice from "pages/Invoice";
import Login from "pages/Login";
import ManageBuyers from "pages/ManageBuyers";
import ManageStaff from "pages/ManageStaff";
// import NewSale from "pages/NewSale";
import NotFound from "pages/NotFound";
import Product from "pages/Product";
import ProductDetail from "pages/ProductDetail";
import Production from "pages/Production";
import RawMaterial from "pages/RawMaterial";
import Register from "pages/Register";
import Sale from "pages/Sale";
import Sales from "pages/Sales";
import SuspendedSales from "pages/SuspendedSales";
import UserDetails from "pages/UserDetails";
import { BrowserRouter, Switch } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PublicRoute restricted={false} component={Login} path="/login" exact />
        <PublicRoute
          restricted={false}
          component={ForgotPassword}
          path="/forgot-password"
          exact
        />
        <PublicRoute
          restricted={false}
          component={Register}
          path="/sign-up"
          exact
        />
        <PrivateRoute component={Sale} path="/" exact />
        <PrivateRoute component={Category} path="/category" exact />
        <PrivateRoute component={RawMaterial} path="/raw-materials" exact />
        <PrivateRoute component={ProductDetail} path="/product-detail" exact />
        <PrivateRoute component={AddCategory} path="/add-category" exact />
        <PrivateRoute component={Product} path="/products" exact />
        <PrivateRoute component={AddProduct} path="/add-products" exact />
        <PrivateRoute component={Sales} path="/sales" exact />
        <PrivateRoute component={Sale} path="/new-sale" exact />
        <PrivateRoute component={Invoice} path="/invoice" exact />
        <PrivateRoute component={ManageBuyers} path="/users" exact />
        <PrivateRoute component={ManageStaff} path="/staffs" exact />
        <PrivateRoute component={UserDetails} path="/users/:id" />
        <PrivateRoute component={SuspendedSales} path="/orders" exact />
        <PrivateRoute component={Production} path="/production" exact />
        <PublicRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
