// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddCategory_AddCategoryWrapper__3NAEv .AddCategory_heading__3kUbX {\n  color: #ae674c; }\n\n.AddCategory_AddCategoryWrapper__3NAEv .AddCategory_content__1g7WM {\n  padding: 10px 0; }\n", "",{"version":3,"sources":["webpack://src/pages/AddCategory/AddCategory.module.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc,EAAA;;AAFlB;EAMI,eAAe,EAAA","sourcesContent":[".AddCategoryWrapper {\n  .heading {\n    color: #ae674c;\n  }\n\n  .content {\n    padding: 10px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddCategoryWrapper": "AddCategory_AddCategoryWrapper__3NAEv",
	"heading": "AddCategory_heading__3kUbX",
	"content": "AddCategory_content__1g7WM"
};
export default ___CSS_LOADER_EXPORT___;
