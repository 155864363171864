/* eslint-disable react/prop-types */

import styles from "./Topbar.module.scss";

function Topbar({ LeftComponent, RightComponent }) {
  const width = screen.width - 232;
  return (
    <div style={{ width: width }} className={styles.TopbarWrapper}>
      {LeftComponent()}
      {RightComponent()}
    </div>
  );
}

export default Topbar;
