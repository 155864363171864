// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuspendedSales_SuspendedSalesWrapper__KAt9L .SuspendedSales_heading__XKp99 {\n  color: #ae674c; }\n\n.SuspendedSales_SuspendedSalesWrapper__KAt9L thead[class*=\"ant-table-thead\"] th {\n  background-color: #fff !important; }\n", "",{"version":3,"sources":["webpack://src/pages/SuspendedSales/SuspendedSales.module.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc,EAAA;;AAFlB;EAKI,iCAAiC,EAAA","sourcesContent":[".SuspendedSalesWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SuspendedSalesWrapper": "SuspendedSales_SuspendedSalesWrapper__KAt9L",
	"heading": "SuspendedSales_heading__XKp99"
};
export default ___CSS_LOADER_EXPORT___;
