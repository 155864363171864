import { DatePicker, message, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./MaterialUsageModal.module.scss";

function MaterialUsageModal({ open, setClose }) {
  const dateFormat = "DD/MM/YYYY";
  const [date, setDate] = useState(null);

  const handleDate = (data) => {
    setDate(moment(data).format("YYYY-MM-DD"));
  };

  const handleOk = () => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/reports/ingredient-usage?date=${date}`,
      headers: {
        "x-auth-token": token(),
      },
      responseType: "blob",
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        fileDownload(response.data, "material-usage-report.xlsx");
        message.success("material-usage-report.xlsx downloaded");
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });

    setClose(false);
  };
  return (
    <div className={styles.MaterialUsageModalWrapper}>
      <Modal
        title="Download Material Usage Report"
        visible={open}
        onOk={handleOk}
        onCancel={() => setClose(close)}
        destroyOnClose
      >
        <Row align="center" justify="center">
          <DatePicker
            format={dateFormat}
            size="large"
            style={{ width: "100%" }}
            onChange={handleDate}
          />
        </Row>
      </Modal>
    </div>
  );
}

export default MaterialUsageModal;
