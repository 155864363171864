// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sales_SalesWrapper__3cy3P .Sales_heading__3eJCF {\n  color: #ae674c; }\n\n.Sales_SalesWrapper__3cy3P thead[class*=\"ant-table-thead\"] th {\n  background-color: #fff !important; }\n\n.Sales_SalesWrapper__3cy3P .Sales_btn__1mtnu {\n  background-color: #d02130;\n  border-color: #d02130;\n  border-radius: 5px;\n  display: flex;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://src/pages/Sales/Sales.module.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc,EAAA;;AAFlB;EAKI,iCAAiC,EAAA;;AALrC;EASI,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,mBAAmB,EAAA","sourcesContent":[".SalesWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n\n  .btn {\n    background-color: #d02130;\n    border-color: #d02130;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SalesWrapper": "Sales_SalesWrapper__3cy3P",
	"heading": "Sales_heading__3eJCF",
	"btn": "Sales_btn__1mtnu"
};
export default ___CSS_LOADER_EXPORT___;
