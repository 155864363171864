import { Button, Input, Modal } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

import styles from "./OtherCostModalContent.module.scss";

function OtherCostModalContent({
  extraCostModal,
  setExtraCostModal,
  setExtraCostRemark,
  extraCost,
  setExtraCost,
  extraCostRemark,
}) {
  const OtherCostSchema = Yup.object().shape({
    cost: Yup.number().required("Enter other cost"),
    remark: Yup.string().required("Enter the Remark"),
  });
  return (
    <div className={styles.OtherCostModalContentWrapper}>
      <Modal
        title="Other Cost"
        visible={extraCostModal}
        footer={null}
        onCancel={() => {
          setExtraCost(0);
          setExtraCostRemark("");
          setExtraCostModal(false);
        }}
        size="small"
        width={450}
        destroyOnClose={true}
      >
        <Formik
          initialValues={{
            cost: extraCost || "",
            remark: extraCostRemark || "",
          }}
          validationSchema={OtherCostSchema}
          onSubmit={(values) => {
            setExtraCost(values.cost);
            setExtraCostRemark(values.remark);
            setExtraCostModal(false);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            // handleReset,
            values,
          }) => (
            <div className="d-flex flex-column w-100">
              <div className="w-100 d-flex align-items-center">
                <Input
                  type="number"
                  className="ml-3 w-100"
                  //   onChange={(e) => setExtraCost(e)}
                  allowClear
                  defaultValue={extraCost}
                  placeholder="Enter the Extra cost"
                  prefix="AED"
                  onChange={handleChange("cost")}
                  onBlur={handleBlur("cost")}
                  value={values.cost}
                />
              </div>

              {errors.cost && touched.cost ? (
                <pre className="text-danger">{errors.cost}</pre>
              ) : null}

              {!(errors.cost && touched.cost) && <div className="my-2" />}
              <Input
                placeholder="Remark"
                // onChange={(e) => setExtraCostRemark(e?.target?.value)}
                onChange={handleChange("remark")}
                onBlur={handleBlur("remark")}
                value={values.remark}
              />
              {errors.remark && touched.remark ? (
                <pre className="text-danger">{errors.remark}</pre>
              ) : null}

              <Button type="primary" className="mt-3" onClick={handleSubmit}>
                {" "}
                OK
              </Button>
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default OtherCostModalContent;
