// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RawMaterial_RawMaterialWrapper__1ngzN .RawMaterial_heading__OTAws {\n  color: #ae674c; }\n\n.RawMaterial_RawMaterialWrapper__1ngzN thead[class*=\"ant-table-thead\"] th {\n  background-color: #fff !important; }\n\n.RawMaterial_RawMaterialWrapper__1ngzN .RawMaterial_btn__2Nlfu {\n  background-color: #d02130;\n  border-color: #d02130;\n  border-radius: 5px;\n  display: flex;\n  align-items: center; }\n\n.RawMaterial_RawMaterialWrapper__1ngzN .RawMaterial_content__3sLJL {\n  margin-top: 30px;\n  padding: 10px 0; }\n", "",{"version":3,"sources":["webpack://src/pages/RawMaterial/RawMaterial.module.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc,EAAA;;AAFlB;EAKI,iCAAiC,EAAA;;AALrC;EASI,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,mBAAmB,EAAA;;AAbvB;EAiBI,gBAAgB;EAChB,eAAe,EAAA","sourcesContent":[".RawMaterialWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n\n  .btn {\n    background-color: #d02130;\n    border-color: #d02130;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n  }\n\n  .content {\n    margin-top: 30px;\n    padding: 10px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RawMaterialWrapper": "RawMaterial_RawMaterialWrapper__1ngzN",
	"heading": "RawMaterial_heading__OTAws",
	"btn": "RawMaterial_btn__2Nlfu",
	"content": "RawMaterial_content__3sLJL"
};
export default ___CSS_LOADER_EXPORT___;
