/* eslint-disable react/prop-types */
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  message,
  Row,
  Space,
  Table,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import AddCustomer from "common/AddCustomer";
import EditCustomer from "common/EditCustomer";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./ManageBuyers.module.scss";

function ManageBuyers({ history }) {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Manage Customers</Breadcrumb.Item>
        <Breadcrumb.Item>All Customers</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Customers</h3>
    </div>
  );
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => setAddCustomer(true)}
    >
      Add Customer
    </Button>
  );
  const [addCustomer, setAddCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);

  const handleCloseModal = () => {
    listUsers();
    setAddCustomer(false);
  };

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const handleUserSearch = (e) => {
    setLoading(true);
    const text = e;
    if (text?.length > 0) {
      const config = {
        method: "get",
        url: `${BASE_URL}/api/search-user?q=${text}`,
        headers: {
          "x-auth-token": token(),
        },
      };

      axios(config)
        .then(function (response) {
          setData(response?.data?.data);
          setLoading(false);
        })
        .catch(function (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );

          setLoading(false);
        });
    }
  };

  const listUsers = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${BASE_URL}/api/users`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setData(response?.data?.data?.reverse());
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    listUsers();
  }, []);

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Orders",
      key: "orders",
      render: (data) => (
        <Button onClick={() => history.push(`users/${data?._id}`, { data })}>
          View
        </Button>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <a
            className="d-flex align-items-center"
            onClick={() => setEditCustomer(data)}
          >
            Edit <EditFilled />
          </a>
          {/* <a className="text-danger">Delete</a> */}
        </Space>
      ),
    },
  ];

  const { Search } = Input;

  return (
    <div className={styles.ManageBuyersWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <Row className="pt-3" gutter={10}>
        <Col span={12}>
          <Search
            placeholder="Enter Customer Name/Mobile Number"
            enterButton="Search"
            onSearch={handleUserSearch}
          />
        </Col>
        <Col>
          <Button onClick={() => listUsers()}>Reset</Button>
        </Col>
      </Row>
      <div className="mt-3">
        <Table
          className="mt-3"
          dataSource={data}
          columns={columns}
          loading={loading}
        />
      </div>
      <Modal
        title="Add New Customer"
        visible={addCustomer}
        onOk={() => setAddCustomer(false)}
        onCancel={() => {
          setAddCustomer(false);
        }}
        size="small"
        destroyOnClose={true}
        footer={null}
      >
        <AddCustomer setAddCustomer={handleCloseModal} />
      </Modal>
      <Modal
        title="Edit Customer"
        visible={editCustomer !== null}
        onOk={() => setEditCustomer(null)}
        onCancel={() => {
          setEditCustomer(null);
        }}
        size="small"
        destroyOnClose={true}
        footer={null}
      >
        <EditCustomer
          setAddCustomer={setEditCustomer}
          data={editCustomer}
          fetch={listUsers}
        />
      </Modal>
    </div>
  );
}

export default ManageBuyers;
