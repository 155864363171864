import { DownOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Carousel,
  Dropdown,
  Empty,
  Menu,
  message,
  Radio,
  Spin,
} from "antd";
import axios from "axios";
import ProductComponent from "common/ProductComponent";
import Topbar from "common/Topbar";
import NewSale from "pages/NewSale";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { save } from "Redux/slice/productSlice";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Dashboard.module.scss";

function Sale({ history }) {
  const [selectedCategory, setSelected] = useState(null);
  const { data } = history?.location?.state || {};
  const { orderitems } = data || {};

  const BussinessType = () => (
    <Menu onClick={handleBusinessSelect}>
      <Menu.Item key="b2c">Retail Business</Menu.Item>
      <Menu.Item key="b2b">Whole Sale Business</Menu.Item>
    </Menu>
  );

  const [businessOpt, setBusinessOpt] = useState(
    data ? data?.ordertype : "b2c"
  );
  const dispatch = useDispatch();
  const handleBusinessSelect = ({ key }) => {
    setBusinessOpt(key);
    dispatch(save([]));
  };

  const RightComponent = () =>
    orderitems ? (
      <div />
    ) : (
      <Dropdown overlay={BussinessType}>
        <Button>
          {businessOpt === "b2c" ? "Retail Business" : "Whole sale business"}{" "}
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/">
          {history?.location?.pathname === "/new-sale" ? "Sales" : "Home"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>
        {history?.location?.pathname === "/new-sale" ? "New Sale" : "Sales"}
      </h3>
    </div>
  );

  function onChange(e) {
    setProducts(null);
    const category = result?.find((item) => item?._id === e?.target?.value);
    setSelected(category);
  }

  useEffect(() => {
    fetchCategory();
  }, []);

  const [loading, setLoading] = useState(true);
  const [result, setresult] = useState([]);

  useEffect(() => {
    if (result && !selectedCategory) setSelected(result?.[0]);
  }, [result]);

  const fetchCategory = () => {
    let config = {
      method: "get",
      url: `${BASE_URL}/api/categories`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setresult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const menu = (data) => {
    return (
      <Menu onClick={handleMenuClick}>
        {data?.map((item) => (
          <Menu.Item key={item?._id}>{item?.name}</Menu.Item>
        ))}
      </Menu>
    );
  };

  function handleMenuClick(e) {
    setProducts(null);
    const category = result?.find((item) => item?._id === e?.key);
    setSelected(category);
  }

  const carouselRef = useRef();

  const [fetching, setFetching] = useState(true);
  const [products, setProducts] = useState(null);

  const fetchProducts = () => {
    setFetching(true);

    let config = {
      method: "get",
      url: `${BASE_URL}/api/products?category=${selectedCategory?._id}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setFetching(false);
        setProducts(response?.data?.data);
      })
      .catch(function (error) {
        setFetching(false);
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (selectedCategory && !products) {
      fetchProducts();
    }
  }, [selectedCategory]);

  const [prodSelect, setProdSelect] = useState(null);

  return (
    <div className={styles.DashboardWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      {loading && (
        <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
          <Spin tip="Loading..." size="large" />
        </div>
      )}
      <div className="w-100 d-flex align-items-center justify-content-center pt-3">
        <Radio.Group
          onChange={onChange}
          defaultValue={selectedCategory?._id}
          value={selectedCategory?._id}
          optionType="button"
          buttonStyle="solid"
          size="large"
        >
          {result?.slice(0, 6)?.map((item) => (
            <Radio.Button key={item?._id} value={item?._id}>
              {item?.name}
            </Radio.Button>
          ))}
          {result?.length > 6 && (
            <Dropdown overlay={() => menu(result?.slice(6, result.length))}>
              <Button size="large">
                More <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Radio.Group>
      </div>
      <h5 className="text-center my-2">{selectedCategory?.name}</h5>
      {fetching ? (
        <div className="w-100 p-5 m-5 d-flex align-items-center justify-content-center">
          <Spin tip="Loading..." size="large" />
        </div>
      ) : products?.length > 0 ? (
        <div>
          <div
            className={styles.Leftarrow}
            onClick={() => carouselRef?.current?.prev()}
          >
            <LeftOutlined />
          </div>
          <Carousel
            slidesToShow={8}
            dots={false}
            ref={carouselRef}
            infinite={false}
          >
            {products?.map((item) => (
              <ProductComponent
                key={item?._id}
                data={item}
                setProdSelect={setProdSelect}
                showRetail={businessOpt === "b2c"}
              />
            ))}
          </Carousel>

          <div
            className={styles.Rightarrow}
            onClick={() => carouselRef?.current?.next()}
          >
            <RightOutlined />
          </div>
        </div>
      ) : (
        <div className="w-100  d-flex align-items-center justify-content-center">
          <Empty />
        </div>
      )}
      <NewSale
        topbar={false}
        prodSelect={prodSelect}
        setProdSelect={setProdSelect}
        showRetail={businessOpt === "b2c"}
      />
    </div>
  );
}

export default Sale;
