import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import Search from "antd/lib/input/Search";
import axios from "axios";
import AddRawMaterial from "common/AddRawMaterial";
import EditRawMaterial from "common/EditRawMaterial";
import MaterialUsageModal from "common/MaterialUsageModal";
import RawMaterialUpload from "common/RawMaterialUpload";
import Topbar from "common/Topbar";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./RawMaterial.module.scss";

function RawMaterial() {
  const [show, setShow] = useState(false);
  const [showReport, setShowreport] = useState(false);
  const [isEdit, setEdit] = useState(null);

  const RightComponent = () => (
    <div className="d-flex">
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          className={styles.btn}
          onClick={() => setShow(true)}
        >
          Add Raw Material
        </Button>
        <Button size="large" onClick={() => setShowreport(true)}>
          Download Usage
        </Button>
      </Space>
    </div>
  );

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Raw Materials</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Raw Materials</h3>
    </div>
  );

  const columns = [
    {
      title: "SL No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Raw Material Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
    },
    {
      title: "Unit Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Edit",
      key: "edit",
      render: (data) => {
        return <Button onClick={() => setEdit(data)}>Edit</Button>;
      },
      width: "5%",
    },
    {
      title: "Delete",
      key: "delete",
      render: (data) => {
        return <Button onClick={() => handleDelete(data?.key)}>Delete</Button>;
      },
      width: "5%",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const getAll = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${BASE_URL}/api/raw-materials`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setResult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  const data = result?.map((item, i) => ({
    id: i + 1,
    name: item?.name,
    unit: item?.unit,
    price: item?.price,
    key: item?._id,
  }));

  const doSearch = (keyword) => {
    setLoading(true);

    const config = {
      method: "get",
      url: `${BASE_URL}/api/search-ingredients?q=${keyword}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setResult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error.data.message);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const handleSearch = (keyword) => doSearch(keyword);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Delete",
      icon: <DeleteOutlined />,
      content: <>Are you sure, you want to delete this Raw material?</>,
      onOk() {
        const config = {
          method: "delete",
          url: `${BASE_URL}/api/raw-materials/${id}`,
          headers: {
            "x-auth-token": token(),
          },
        };

        axios(config)
          .then(function (response) {
            getAll();
            message.success(response.data.message);
          })
          .catch(function (error) {
            message.error(error?.response?.data?.message);
          });
      },
      onCancel() {},
    });
  };

  const handleDownload = () => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/export-materials`,
      responseType: "blob",
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        fileDownload(response?.data, "raw-materials.xlsx");
        message.success("rawmaterials.xlsx downloaded");
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const [showUpload, setUpload] = useState(false);

  return (
    <div className={styles.RawMaterialWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <Row className="pt-3" gutter={10} justify="space-between">
        <Col span={12}>
          <Row gutter={10}>
            <Col span={16}>
              <Search
                placeholder="Enter Raw Material Name"
                enterButton="Search"
                onSearch={handleSearch}
              />
            </Col>
            <Col>
              <Button onClick={getAll}>Reset</Button>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <Space>
              <Button type="primary" onClick={() => setUpload(true)}>
                Upload
              </Button>
              <Button onClick={() => handleDownload()}>Download</Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <div className={styles.content}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={data?.length > 20}
          loading={loading}
        />
      </div>
      <AddRawMaterial show={show} setShow={setShow} getAll={getAll} />
      <EditRawMaterial
        show={isEdit !== null}
        result={isEdit}
        setShow={setEdit}
        getAll={getAll}
      />
      <RawMaterialUpload open={showUpload} setUpload={setUpload} />
      <MaterialUsageModal open={showReport} setClose={setShowreport} />
    </div>
  );
}

export default RawMaterial;
