// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddRawMaterial_AddRawMaterialWrapper__3Kevr .AddRawMaterial_heading__2vNNu {\n  color: #d02130;\n  text-align: center; }\n\n.AddRawMaterial_AddRawMaterialWrapper__3Kevr .AddRawMaterial_content__3lP8B {\n  padding: 10px 20px; }\n  .AddRawMaterial_AddRawMaterialWrapper__3Kevr .AddRawMaterial_content__3lP8B button {\n    width: 100%;\n    margin: 0 10px;\n    border: 0; }\n  .AddRawMaterial_AddRawMaterialWrapper__3Kevr .AddRawMaterial_content__3lP8B .AddRawMaterial_cancel__kNdjb {\n    background-color: #616161;\n    color: #fff;\n    border-radius: 5px; }\n  .AddRawMaterial_AddRawMaterialWrapper__3Kevr .AddRawMaterial_content__3lP8B .AddRawMaterial_submit__3Buh8 {\n    background-color: #d02130;\n    color: #fff;\n    border-radius: 5px; }\n", "",{"version":3,"sources":["webpack://src/common/AddRawMaterial/AddRawMaterial.module.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc;EACd,kBAAkB,EAAA;;AAHtB;EAMI,kBAAkB,EAAA;EANtB;IASM,WAAW;IACX,cAAc;IACd,SAAS,EAAA;EAXf;IAeM,yBAAyB;IACzB,WAAW;IACX,kBAAkB,EAAA;EAjBxB;IAoBM,yBAAyB;IACzB,WAAW;IACX,kBAAkB,EAAA","sourcesContent":[".AddRawMaterialWrapper {\n  .heading {\n    color: #d02130;\n    text-align: center;\n  }\n  .content {\n    padding: 10px 20px;\n\n    button {\n      width: 100%;\n      margin: 0 10px;\n      border: 0;\n    }\n\n    .cancel {\n      background-color: #616161;\n      color: #fff;\n      border-radius: 5px;\n    }\n    .submit {\n      background-color: #d02130;\n      color: #fff;\n      border-radius: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddRawMaterialWrapper": "AddRawMaterial_AddRawMaterialWrapper__3Kevr",
	"heading": "AddRawMaterial_heading__2vNNu",
	"content": "AddRawMaterial_content__3lP8B",
	"cancel": "AddRawMaterial_cancel__kNdjb",
	"submit": "AddRawMaterial_submit__3Buh8"
};
export default ___CSS_LOADER_EXPORT___;
