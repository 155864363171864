import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Divider, message, Popconfirm } from "antd";
import { Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import EditCategory from "common/EditCategory";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Category.module.scss";

function Category() {
  const [isModalVisible, setIsModalVisible] = useState(null);

  const showModal = (data) => {
    setIsModalVisible(data);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <img src={src} alt="product-img" className={styles.productImg} />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Displayed",
      key: "active",
      render: (bool) => (
        <Space size="middle">
          {bool?.active ? (
            <CheckOutlined className="text-success" />
          ) : (
            <CloseOutlined className="text-danger" />
          )}
        </Space>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      render: (data) => (
        <Space size="middle">
          <EditFilled onClick={() => showModal(data)} />
        </Space>
      ),
    },

    {
      title: "Delete",
      key: "delete",
      render: (item) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this Category?"
            onConfirm={() => handleDelete(item?.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled className="text-danger" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Categories</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Categories</h3>
    </div>
  );

  useEffect(() => {
    fetchCategory();
  }, []);

  const [loading, setLoading] = useState(true);
  const [result, setresult] = useState([]);

  const fetchCategory = () => {
    let config = {
      method: "get",
      url: `${BASE_URL}/api/categories`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setresult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => history.push("/add-category")}
    >
      Add Category
    </Button>
  );
  const history = useHistory();

  let data = result?.map((item) => ({
    id: item?._id,
    name: item?.name,
    description: item?.description,
    active: item?.isdisplayed,
    image: BASE_URL + "/" + item?.imagekey,
  }));

  const handleDelete = (id) => {
    setLoading(true);
    let config = {
      method: "delete",
      url: `${BASE_URL}/api/categories/${id}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        fetchCategory();
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={styles.CategoryWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className={styles.content}>
        {/* <Row gutter={16} align="middle" justify="space-between">
          <Col span={6}>
            <Card className={styles.card}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h4>2</h4>
                  <p>Disabled Categories</p>
                </div>
                <StopFilled className={styles.icon} />
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.card}>
              {" "}
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h4>10</h4>
                  <p>Empty Categories</p>
                </div>
                <ShoppingFilled className={styles.icon} />
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className={styles.card}>
              {" "}
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h4>8</h4>
                  <p>Average number of products per category</p>
                </div>
                <GoldenFilled className={styles.icon} />
              </div>
            </Card>
          </Col>
          <Col span={6}>
            {" "}
            <Button
              type="primary"
              shape="round"
              icon={<ReloadOutlined />}
              size="large"
              onClick={fetchCategory}
              className="d-flex align-items-center"
            >
              Refresh
            </Button>
          </Col>
        </Row>
        */}
        <div>
          <h6 className="mb-2 pt-4 text-success">
            Categories({data?.length || 0})
          </h6>
        </div>
        <Divider className="my-2" />
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
      <Modal
        footer={null}
        title="Edit Category"
        visible={isModalVisible !== null}
        setIsModalVisible={setIsModalVisible}
        onCancel={() => setIsModalVisible(null)}
        destroyOnClose={true}
      >
        <EditCategory
          data={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          fetchCategory={fetchCategory}
        />
      </Modal>
    </div>
  );
}

export default Category;
