/* eslint-disable react/prop-types */
import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Space, Switch, Upload } from "antd";
import axios from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { getUrlExtension, token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./EditCategory.module.scss";
function EditCategory({ data, setIsModalVisible, fetchCategory }) {
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const onImageChange = (e) => {
    setImageUrl(e?.fileList);
  };
  const { TextArea } = Input;
  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    displayed: Yup.boolean(),
    description: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .max(50, "Too Long!"),
  });

  useEffect(() => {
    if (data?.image && imageUrl?.length === 0) {
      setImageUrl([{ thumbUrl: data?.image }]);
    }
  }, [data]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleResetAll = (reset) => {
    reset();
    setImageUrl([]);
    setIsModalVisible(null);
  };
  const onSubmit = (submit) => {
    if (imageUrl?.length > 0) {
      submit();
    } else {
      message.error("Please attach image to continue");
    }
  };

  const handleEdit = async (values) => {
    const FormData = require("form-data");
    const formdata = new FormData();
    formdata.append("name", values?.name);
    formdata.append("isdisplayed", values?.displayed);
    formdata.append("description", values?.description);
    if (imageUrl?.[0]?.originFileObj) {
      formdata.append("image", imageUrl?.[0]?.originFileObj);

      const config = {
        method: "put",
        url: `${BASE_URL}/api/categories/${data?.id}`,
        headers: {
          "x-auth-token": token(),
          "Content-Type": "multipart/form-data",
        },
        data: formdata,
      };

      axios(config)
        .then(function (response) {
          setLoading(false);
          fetchCategory();
          message.success(response?.data?.message);
          setImageUrl([]);
          setIsModalVisible(null);
        })
        .catch(function (error) {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else {
      const url = imageUrl?.[0]?.thumbUrl;
      const imgExt = getUrlExtension(url);
      const fileName = `myFile.${imgExt}`;

      fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        formdata.append("image", file);
        const config = {
          method: "put",
          url: `${BASE_URL}/api/categories/${data?.id}`,
          headers: {
            "x-auth-token": token(),
            "Content-Type": "multipart/form-data",
          },
          data: formdata,
        };

        axios(config)
          .then(function (response) {
            fetchCategory();
            setLoading(false);
            message.success(response?.data?.message);
            setImageUrl([]);
            setIsModalVisible(null);
          })
          .catch(function (error) {
            setLoading(false);
            message.error(error?.response?.data?.message);
          });
      });
    }
  };

  return (
    <div className={styles.EditCategoryWrapper}>
      <Formik
        initialValues={{
          name: data?.name,
          displayed: data?.active,
          description: data?.description,
        }}
        enableReinitialize={true}
        validationSchema={CategorySchema}
        onSubmit={(values) => {
          setLoading(true);
          handleEdit(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          setFieldValue,
          values,
        }) => (
          <>
            <Col>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right">
                    Category Name <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Input
                    placeholder="Category Name"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                  />
                  <pre className="text-danger">
                    {errors.name && touched.name ? (
                      <div>{errors.name}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Displayed <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Switch
                    defaultChecked
                    onChange={(e) => setFieldValue("displayed", e)}
                    value={values.displayed}
                  />
                </Col>
              </Row>
              <Row align="top" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Description <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <TextArea
                    rows={4}
                    placeholder="Description"
                    onChange={handleChange("description")}
                    onBlur={handleBlur("description")}
                    value={values.description}
                  />

                  <pre className="text-danger">
                    {errors.description && touched.description ? (
                      <div>{errors.description}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Category Image <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={true}
                    onChange={(e) => onImageChange(e)}
                    accept="image/*"
                    fileList={imageUrl}
                    onSubmit={() => {}}
                    maxCount={1}
                  >
                    {uploadButton}
                  </Upload>
                </Col>
              </Row>
            </Col>
            <Row align="middle" justify="center">
              <Space>
                <Button
                  type="secondary"
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={() => handleResetAll(handleReset)}
                  className="d-flex align-items-center"
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  size="large"
                  onClick={() => onSubmit(handleSubmit)}
                  className="d-flex align-items-center"
                  loading={loading}
                >
                  Save
                </Button>
              </Space>
            </Row>
          </>
        )}
      </Formik>{" "}
    </div>
  );
}

export default EditCategory;
