import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  // Divider,
  Input,
  message,
  Row,
  Space,
  Switch,
  Upload,
} from "antd";
import axios from "axios";
import Topbar from "common/Topbar";
import { Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddCategory.module.scss";

function AddCategory() {
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onImageChange = (e) => {
    setImageUrl(e?.fileList);
  };
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/products">Catalog</Breadcrumb.Item>
        <Breadcrumb.Item href="/category">Category</Breadcrumb.Item>
        <Breadcrumb.Item> Add Category</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Add Category</h3>
    </div>
  );
  const RightComponent = () => <div />;
  const { TextArea } = Input;

  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the category name"),
    displayed: Yup.boolean(),
    description: Yup.string()
      .required("Please enter category description")
      .min(2, "Too Short!")
      .max(150, "Too Long!"),
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleResetAll = (reset) => {
    reset();
    setImageUrl(null);
  };
  const onSubmit = (submit) => {
    if (imageUrl?.length > 0) {
      submit();
    } else {
      message.error("Please attach image to continue");
    }
  };
  return (
    <div className={styles.AddCategoryWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />

      <div className={styles.content}>
        {/* <h5 className="mb-1">Add New Category</h5> */}
        {/* <Divider className="my-2" /> */}
        <Formik
          initialValues={{
            name: "",
            displayed: true,
            description: "",
          }}
          validationSchema={CategorySchema}
          onSubmit={(values) => {
            setLoading(true);
            let FormData = require("form-data");
            let data = new FormData();
            data.append("name", values.name);
            data.append("isdisplayed", values.displayed);
            data.append("description", values.description);
            data.append("image", imageUrl?.[0]?.originFileObj);

            let config = {
              method: "post",
              url: `${BASE_URL}/api/categories`,
              headers: {
                "x-auth-token": token(),
                "Content-Type": "multipart/form-data",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                setLoading(false);
                message.success(response?.data?.message);
                history.push("/category");
              })
              .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
              });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            setFieldValue,
            values,
          }) => (
            <>
              <Col className="p-3">
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Category Name <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Input
                      placeholder="Category Name"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                    />
                    <pre className="text-danger">
                      {errors.name && touched.name ? (
                        <div>{errors.name}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Displayed <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Switch
                      defaultChecked
                      onChange={(e) => setFieldValue("displayed", e)}
                      value={values.displayed}
                    />
                  </Col>
                </Row>
                <Row align="top" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Description <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <TextArea
                      rows={4}
                      placeholder="Description"
                      onChange={handleChange("description")}
                      onBlur={handleBlur("description")}
                      value={values.description}
                    />

                    <pre className="text-danger">
                      {errors.description && touched.description ? (
                        <div>{errors.description}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Category Image <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={true}
                      onChange={(e) => onImageChange(e)}
                      accept="image/*"
                      fileList={imageUrl}
                      onSubmit={() => {}}
                      maxCount={1}
                    >
                      {uploadButton}
                    </Upload>
                  </Col>
                </Row>
              </Col>
              <Row align="middle" justify="center">
                <Space>
                  <Button
                    type="secondary"
                    icon={<CloseOutlined />}
                    size="large"
                    onClick={() => handleResetAll(handleReset)}
                    className="d-flex align-items-center"
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    onClick={() => onSubmit(handleSubmit)}
                    className="d-flex align-items-center"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Space>
              </Row>
            </>
          )}
        </Formik>{" "}
      </div>
    </div>
  );
}

export default AddCategory;
