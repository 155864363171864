export const isLogin = () => {
  if (localStorage.getItem("KIOSKY_TOKEN")) {
    return true;
  }

  return false;
};

export const token = () => {
  return localStorage.getItem("KIOSKY_TOKEN");
};

export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};
