import {
  AppstoreAddOutlined,
  CloseCircleFilled,
  DeleteOutlined,
  EditFilled,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  PauseOutlined,
  PlusOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import AddCustomer from "common/AddCustomer";
import Topbar from "common/Topbar";
import OtherCostModalContent from "pages/OtherCostModalContent";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { save } from "Redux/slice/productSlice";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./NewSale.module.scss";

function NewSale({
  topbar = true,
  prodSelect,
  setProdSelect,
  showRetail = true,
}) {
  const dispatch = useDispatch();
  let productsArr = useSelector((state) => state?.products?.value);

  const [showAdjust, setShowAdjust] = useState(false);
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/sales">Sales</Breadcrumb.Item>
        <Breadcrumb.Item>New Sales</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Add Sale</h3>
    </div>
  );

  const history = useHistory();
  const { data } = history?.location?.state || {};
  const { orderitems } = data || {};

  const isRetail =
    orderitems?.length > 0 ? data?.ordertype === "b2c" : showRetail;

  console.log(`data`, data);

  useEffect(() => {
    return () => {
      handleVoid();
    };
  }, [history.location.pathname]);

  const getVatFreePrice = (price) => {
    const newPrice = (Number(price) - Number(price) * 0.05).toFixed(2);
    return Number(newPrice);
  };

  const orderArr =
    orderitems?.map((item) => ({
      _id: item?.id,
      key: item?.id,
      name: item?.name,
      newTotal: item?.total,
      discount: item?.discount,
      qty: item?.qty,
      newPrice: item?.price,
      stockqty: item?.stockqty,
      unit: item?.unit || "nos",
      retailprice: item?.originalprice,
      wholesaleprice: item?.originalprice,
      // ...item,
    })) || [];

  const RightComponent = () => <div />;

  const [searchRes, setSearch] = useState(null);
  const [userRes, setUser] = useState(null);

  const handleSearch = (e) => {
    if (e?.target?.value?.length > 0) {
      setShowRes(true);
      const config = {
        method: "get",
        url: `${BASE_URL}/api/search-product?q=${e?.target?.value}`,
        headers: {
          "x-auth-token": token(),
        },
      };
      axios(config)
        .then(function (response) {
          setSearch(response?.data?.data);
        })
        .catch(function (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );
        });
    }
  };

  useEffect(() => {
    if (orderArr?.length > 0) {
      dispatch(save(orderArr));
    }
  }, []);
  const [showRes, setShowRes] = useState(false);
  const [showUser, setShowUser] = useState(false);
  // const [products, setProducts] = useState(orderArr);
  const [isEdit, setEdit] = useState(null);
  const [selectedUser, setUserSelect] = useState(data?.user || null);
  const [adjust, setAdjust] = useState(data?.adjustment || 0);

  const isGuest =
    (selectedUser?.username === "GUEST USER" ||
      selectedUser?.username === "Guest User") ??
    false;

  const columns = [
    {
      title: () => <PlusOutlined className="text-danger" />,
      key: "id",
      render: (data) => (
        <CloseCircleFilled
          className="text-danger"
          onClick={() => handleRemove(data?.key)}
        />
      ),
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Price (VAT exclusive)",
      key: "price",
      render: (text) => (
        <span className="text-center">
          {isGuest ? 0 : `AED ${text?.newPrice?.toFixed(2)}`}
        </span>
      ),
    },
    {
      title: "Qty",
      key: "qty",
      render: (data) => (
        <Row align="middle">
          <Space>
            <p className="mb-0 pr-2">{data?.qty}</p>
            <select
              value={data?.unit}
              onChange={(e) => handleUnitChange(e, data?._id)}
            >
              <option value="nos">Nos</option>
              <option value="gram">Gram</option>
            </select>

            <EditFilled className="mb-2" onClick={() => setEdit(data?.key)} />
          </Space>
        </Row>
      ),
    },
    {
      title: "Total",
      render: (text) => (
        <span>{isGuest ? 0 : `AED ${text?.newTotal?.toFixed(2)}`}</span>
      ),
      key: "total",
    },
  ];

  const handleQtyChange = (e, data1, edit = false) => {
    const num = e || 1;
    const data = edit
      ? productsArr?.find((item) => item?.key === data1)
      : data1;

    const obj = {
      key: data?._id,
      name: data?.name,
      newTotal:
        data?.unit === "gram"
          ? num *
            (getVatFreePrice(
              isRetail ? data.retailprice : data?.wholesaleprice
            ) /
              1000)
          : num *
            getVatFreePrice(isRetail ? data.retailprice : data?.wholesaleprice),
      discount: data?.discount,
      qty: data?.unit === "gram" ? (edit ? num : data?.qty) : num,
      newPrice: getVatFreePrice(
        isRetail ? data.retailprice : data?.wholesaleprice
      ),
      stockqty: data?.stockqty,
      vat: data?.vat || 0,
      unit: data?.unit || "nos",
      price: data?.wholesaleprice,
      retailprice: data.retailprice,
      wholesaleprice: data?.wholesaleprice,
      _id: data?._id,
    };

    productsArr = productsArr.slice();
    const productIndex = productsArr.findIndex(
      (prod) => prod._id === data?._id
    );
    if (productIndex !== -1) productsArr[productIndex] = obj;
    dispatch(save(productsArr));
  };

  const handleUnitChange = (e, key) => {
    const selected = e?.target?.value;
    const data = productsArr?.find((item) => item?.key === key);
    let obj = {
      key: data?._id,
      name: data?.name,
      newTotal: getVatFreePrice(
        isRetail ? data.retailprice : data?.wholesaleprice
      ),
      discount: data?.discount,
      qty: selected === "gram" ? 1000 : 1,
      newPrice: getVatFreePrice(
        isRetail ? data.retailprice : data?.wholesaleprice
      ),
      stockqty: data?.stockqty,
      vat: data?.vat || 0,
      unit: selected,
      price: data?.wholesaleprice,
      retailprice: data?.retailprice,
      wholesaleprice: data?.wholesaleprice,
      _id: data?._id,
    };
    productsArr = productsArr.slice();
    const productIndex = productsArr.findIndex(
      (prod) => prod._id === data?._id
    );
    if (productIndex !== -1) productsArr[productIndex] = obj;
    dispatch(save(productsArr));
  };

  const handleRemove = (key) => {
    dispatch(save(productsArr?.filter((item) => item?.key !== key)));
  };

  const [internalNotes, setInternalNotes] = useState("");

  const handleSelection = (data) => {
    setShowRes(false);
    let obj = {
      key: data?._id,
      name: data?.name,
      newTotal: getVatFreePrice(
        isRetail ? data?.retailprice : data?.wholesaleprice
      ),
      discount: data?.discount || 0,
      qty: 1,
      newPrice: getVatFreePrice(
        isRetail ? data?.retailprice : data?.wholesaleprice
      ),
      stockqty: data?.stockqty,
      vat: data?.vat || 0,
      unit: data?.unit || "nos",
      retailprice: data?.retailprice,
      _id: data?._id,
      price: data?.wholesaleprice,
      wholesaleprice: data?.wholesaleprice,
      // ...data,
    };

    if (
      productsArr?.length > 0 &&
      productsArr?.find((item) => item?.key === data?._id)
    ) {
      let req = productsArr?.find((item) => item?.key === data?._id);
      let qty = req.qty + 1;
      handleQtyChange(qty, obj);
    } else {
      dispatch(save([...productsArr, ...[obj]]));
    }
  };

  const handleUserSelection = (data) => {
    setShowUser(false);
    setUserSelect(data);
  };

  const searchRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowRes(false);
    }
  };

  const userRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleUserClickOutside, false);
    return () => {
      document.removeEventListener("click", handleUserClickOutside, false);
    };
  }, []);

  const handleUserClickOutside = (event) => {
    if (userRef.current && !userRef.current.contains(event.target)) {
      setShowUser(false);
    }
  };

  const handleUserSearch = (e) => {
    const text = e?.target?.value;
    if (text?.length > 0) {
      setShowUser(true);
      const config = {
        method: "get",
        url: `${BASE_URL}/api/search-user?q=${text}`,
        headers: {
          "x-auth-token": token(),
        },
      };

      axios(config)
        .then(function (response) {
          setUser(response?.data?.data);
        })
        .catch(function (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );
        });
    }
  };
  const { TextArea } = Input;

  const getSubTotal = () => {
    let total = 0;
    productsArr?.forEach((item) => (total = total + item?.newTotal));
    return parseFloat((total - discountPrice)?.toFixed(2));
  };

  const getProductsVat = () => {
    let prodVat = 0;
    productsArr?.forEach((item) => {
      console.log(`item`, item);
      const vatAmount =
        item?.unit === "gram"
          ? item?.qty *
              ((isRetail ? item?.retailprice : item?.wholesaleprice) / 1000) -
            item?.newTotal
          : item?.qty * (isRetail ? item?.retailprice : item?.wholesaleprice) -
            item?.newTotal;
      prodVat = Number(prodVat) + Number(vatAmount);
    });

    return parseFloat(prodVat?.toFixed(2));
  };
  const getVat = () => {
    return parseFloat(
      (
        (Number(deliveryFee) + Number(extraCost || 0)) * 0.05 +
        Number(getProductsVat())
      )?.toFixed(2)
    );
  };

  const generateInvoice = (type) => {
    const data = JSON.stringify({
      items: productsArr?.map((item) => ({
        pid: item?.key,
        qty: item?.qty,
        unit: item?.unit,
        // vat: item?.vat,
        // discount: item?.discount,
      })),
      userid: selectedUser?._id,
      internalnotes: internalNotes,
      mobilereciept: false,
      salesdiscount: discountPrice,
      deliverycharge: deliveryFee,
      adjustment: adjust,
      ordertype: isRetail ? "b2c" : "b2b",
      othercosts: {
        description: extraCostRemark,
        amount: extraCost || 0,
      },
    });

    const config = {
      method: "post",
      url:
        type === "suspend"
          ? `${BASE_URL}/api/orders?status=suspended`
          : `${BASE_URL}/api/orders`,
      headers: {
        "x-auth-token": token(),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (type === "suspend") {
          message.success("Order sucessfully suspended");
          handleVoid();
        } else {
          message.success(response.data.message);
          handleVoid();
          history.push("/invoice", { data: response.data.data });
        }
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const editInvoice = () => {
    const data1 = JSON.stringify({
      items: productsArr?.map((item) => ({
        pid: item?.key,
        qty: item?.qty,
        unit: item?.unit,
      })),
      userid: selectedUser?._id,
      internalnotes: internalNotes,
      mobilereciept: false,
      salesdiscount: discountPrice,
      deliverycharge: deliveryFee,
      adjustment: adjust,
      othercosts: {
        description: extraCostRemark,
        amount: extraCost || 0,
      },
    });

    var config = {
      method: "patch",
      url: `${BASE_URL}/api/orders/${data?._id}`,
      headers: {
        "x-auth-token": token(),
        "Content-Type": "application/json",
      },
      data: data1,
    };

    axios(config)
      .then(function (response) {
        message.success(response.data.message);
        handleVoid();
        history.push("/invoice", { data: response.data.data });
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const handleVoid = () => {
    dispatch(save([]));
    setUserSelect(null);
    setDiscount(0);
    setAdjust(0);
    setExtraCost(0);
    setInternalNotes("");
  };

  const [showDiscount, setShowDiscount] = useState(false);
  const [discountPrice, setDiscount] = useState(data?.salesdiscount || 0);
  const [showDelivery, setshowDelivery] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(data?.deliverycharge || 0);
  const [addCustomer, setAddCustomer] = useState(false);
  const [extraCost, setExtraCost] = useState(data?.othercosts?.amount || "");
  const [extraCostModal, setExtraCostModal] = useState(false);
  const [extraCostRemark, setExtraCostRemark] = useState(
    data?.othercosts?.description || ""
  );

  useEffect(() => {
    if (prodSelect) {
      handleSelection(prodSelect);
      setProdSelect(null);
    }
  }, [prodSelect]);

  return (
    <div className={styles.NewSaleWrapper}>
      {topbar && (
        <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      )}
      <Modal
        title="Change Quantity"
        visible={isEdit !== null}
        onOk={() => setEdit(null)}
        onCancel={() => setEdit(null)}
        size="small"
        width={250}
        destroyOnClose={true}
      >
        <div className="d-flex flex-column justify-content-center w-100  align-items-center">
          <InputNumber
            defaultValue={
              productsArr?.find((item) => item?.key === isEdit)?.qty
            }
            onChange={(e) => handleQtyChange(e, isEdit, true)}
            min={1}
          />
        </div>
      </Modal>
      <div className={styles.content}>
        <Row gutter={20}>
          <Col span={16} className="mr-3">
            <div className="p-3 bg-white" ref={searchRef}>
              <Input.Group size="large">
                <Row>
                  <Col span={3}>
                    <Button
                      className="w-100 h-lg-100 rounded-0"
                      type="primary"
                      icon={<AppstoreAddOutlined />}
                      size="large"
                      onClick={() => history.push("/add-products")}
                    />
                  </Col>
                  <Col span={18}>
                    <Input
                      // onBlur={() => setShowRes(false)}
                      onChange={handleSearch}
                      placeholder="Enter product name"
                      suffix={<SearchOutlined className={styles.searchIcon} />}
                      allowClear
                    />
                  </Col>
                  <Col span={3} onClick={() => history.push("/sales")}>
                    <Row
                      align="middle"
                      justify="center"
                      className={styles.searchBtn}
                    >
                      <ShoppingCartOutlined className={styles.icon} />
                      <p className="m-0 d-none d-lg-block">Sales</p>
                    </Row>
                  </Col>
                </Row>
              </Input.Group>
              {showRes && (
                <div
                  className={styles.searchResult}
                  onMouseOver={() => setShowRes(true)}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={searchRes?.length > 0 ? [...searchRes] : []}
                    renderItem={(item) => (
                      <List.Item onClick={() => handleSelection(item)}>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              shape="square"
                              size="large"
                              src={BASE_URL + "/" + item?.imagekeys?.[0]}
                            />
                          }
                          title={item.name}
                          description={item?.description}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </div>
            <Table
              columns={columns}
              dataSource={productsArr}
              pagination={false}
              className="mt-3"
            />
          </Col>
          <Col span={8}>
            <div className="bg-white p-3">
              <Row>
                <Space>
                  <Button
                    icon={
                      <EllipsisOutlined className="text-danger text-bold" />
                    }
                  />
                  {productsArr?.length > 0 && selectedUser && (
                    <>
                      <Button
                        icon={<PauseOutlined />}
                        className={styles.suspendBtn}
                        onClick={() => {
                          Modal.confirm({
                            title: "Suspend Order",
                            icon: <ExclamationCircleOutlined />,
                            content: "Are you sure you suspend this order?",
                            okText: "Sure",
                            cancelText: "Cancel",
                            onOk: () => generateInvoice("suspend"),
                          });
                        }}
                      >
                        Suspend Sale
                      </Button>
                      <Button
                        className={styles.voidBtn}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            title: "Reset Order",
                            icon: <ExclamationCircleOutlined />,
                            content: "Are you sure you cancel this order?",
                            okText: "Sure",
                            cancelText: "Cancel",
                            onOk: () => handleVoid(),
                          });
                        }}
                      >
                        Void/Delete
                      </Button>
                    </>
                  )}
                </Space>
              </Row>
              <Divider className="my-2" />
              {!selectedUser && (
                <div ref={userRef}>
                  <Input.Group size="large">
                    <Row>
                      <Col span={3}>
                        <Button
                          className="w-100 h-100 rounded-0"
                          type="primary"
                          icon={<UserAddOutlined />}
                          size="large"
                          onClick={() => setAddCustomer(true)}
                        />
                      </Col>
                      <Col span={21}>
                        <Input
                          onChange={handleUserSearch}
                          placeholder="Enter customer name/mobile"
                          allowClear
                          suffix={
                            <SearchOutlined className={styles.searchIcon} />
                          }
                        />
                      </Col>
                    </Row>
                  </Input.Group>
                  {showUser && (
                    <div className={styles.userResult}>
                      <List
                        itemLayout="horizontal"
                        dataSource={userRes || []}
                        renderItem={(item) => (
                          <List.Item onClick={() => handleUserSelection(item)}>
                            <List.Item.Meta
                              title={item.username}
                              description={item?.phonenumber}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              {/* User Section */}
              {selectedUser && (
                <Row className="my-2">
                  <Col>
                    <Row align="middle" justify="space-around">
                      <Space>
                        <Col>
                          <Avatar
                            size={64}
                            className="d-flex justify-content-center align-items-center"
                            icon={<UserOutlined />}
                          />
                        </Col>
                        <Col>
                          <p className="mb-0">{selectedUser?.username}</p>
                          <Text type="secondary">
                            {selectedUser?.phonenumber}
                          </Text>
                        </Col>
                      </Space>
                    </Row>
                  </Col>

                  <TextArea
                    placeholder="Internal notes"
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    className="my-2 w-100"
                    onChange={(e) => setInternalNotes(e?.target?.value)}
                  />
                  <Divider className="mt-1 mb-0" />
                  <Row align="middle" justify="space-around" className="w-100">
                    <Checkbox>Delivery</Checkbox>
                    <Divider type="vertical" className="m-0 px-1 py-3" />
                    <Checkbox>Mobile Reciept</Checkbox>
                    <Divider type="vertical" className="m-0 py-3" />
                    <Button
                      icon={<CloseCircleFilled className="text-danger" />}
                      className="d-flex border-0 align-items-center"
                      onClick={() => setUserSelect(null)}
                    >
                      Detach
                    </Button>
                  </Row>
                </Row>
              )}
            </div>

            <div className="bg-white mt-3">
              <div className={styles.finaltopText}>
                <p>Discount:</p>
                {discountPrice ? (
                  <div className="d-flex">
                    <p>AED {discountPrice}</p>
                    <EditFilled onClick={() => setShowDiscount(true)} />
                  </div>
                ) : isGuest ? (
                  0
                ) : (
                  <a onClick={() => setShowDiscount(true)}>Set Discount</a>
                )}
              </div>

              <Divider className="my-1" />
              <div className={styles.finaltopText1}>
                <p>Other Charges</p>
                <a onClick={() => setExtraCostModal(true)}>
                  AED {isGuest ? 0 : extraCost > 0 ? extraCost : 0}
                </a>
              </div>

              {extraCostRemark && (
                <span className={styles.span}>{extraCostRemark}</span>
              )}

              {!extraCostRemark && <div className="py-2" />}

              <div className={styles.finalMiddleText}>
                <p>Sub Total:</p>
                <a className="text-decoration-none">
                  <span className={styles.currency}>AED&nbsp;</span>
                  {isGuest ? 0 : getSubTotal()}
                </a>
              </div>
              <div className={styles.finaltopText}>
                <p>5% VAT</p>
                <a className="text-decoration-none">
                  AED {isGuest ? 0 : getVat()}
                </a>
              </div>
              <Divider className="my-1" />
              <div className={styles.finaltopText}>
                <p>Adjustments</p>
                <a onClick={() => setShowAdjust(true)}>AED {adjust}</a>
              </div>
              <Divider className="my-1" />
              <div className={styles.finaltopText}>
                <p>Delivery Fee</p>
                <a onClick={() => setshowDelivery(true)}>
                  AED {isGuest ? 0 : deliveryFee > 0 ? deliveryFee : 0}
                </a>
              </div>

              <div className={styles.priceBox}>
                <div>
                  <p>Total</p>
                  <h4 className="text-success">
                    AED{" "}
                    {isGuest
                      ? 0
                      : (
                          getSubTotal() +
                          getVat() +
                          deliveryFee +
                          adjust +
                          Number(extraCost || 0)
                        ).toFixed(2)}
                  </h4>
                </div>

                <Divider type="vertical" className="m-0 py-5" />
                <div>
                  <p>Amount Due</p>
                  <h4 className="text-warning">
                    AED{" "}
                    {isGuest
                      ? 0
                      : (
                          getSubTotal() +
                          getVat() +
                          deliveryFee +
                          adjust +
                          Number(extraCost || 0)
                        ).toFixed(2)}
                  </h4>
                </div>
              </div>
              {selectedUser && (
                <Button
                  className={styles.generateBtn}
                  onClick={() =>
                    data ? editInvoice() : generateInvoice("generate")
                  }
                >
                  Generate Invoice
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>

      {/* Discount Fee */}
      <Modal
        title="Set Discount"
        visible={showDiscount}
        onOk={() => setShowDiscount(false)}
        onCancel={() => {
          setShowDiscount(false);
          setDiscount(0);
        }}
        size="small"
        width={250}
        destroyOnClose={true}
      >
        <div className="d-flex flex-column justify-content-center w-100  align-items-center">
          <p>Enter Discount Amount</p>
          <InputNumber
            onChange={(e) => setDiscount(e)}
            allowClear
            defaultValue={discountPrice}
          />
        </div>
      </Modal>

      {/* Delivery Fee */}
      <Modal
        title="Set Delivery Fee"
        visible={showDelivery}
        onOk={() => setshowDelivery(false)}
        onCancel={() => {
          setshowDelivery(false);
          setDeliveryFee(0);
        }}
        size="small"
        width={250}
        destroyOnClose={true}
      >
        <div className="d-flex flex-column justify-content-center w-100  align-items-center">
          <p>Enter Delivery Fee</p>
          <InputNumber
            onChange={(e) => setDeliveryFee(e)}
            allowClear
            defaultValue={deliveryFee}
          />
        </div>
      </Modal>

      {/* Add New Customer */}
      <Modal
        title="Add New Customer"
        visible={addCustomer}
        onOk={() => setAddCustomer(false)}
        onCancel={() => {
          setAddCustomer(false);
        }}
        size="small"
        destroyOnClose={true}
        footer={null}
      >
        <AddCustomer
          setAddCustomer={setAddCustomer}
          handleUserSelection={handleUserSelection}
        />
      </Modal>

      {/* Ajustment Modal */}

      <Modal
        title="Price Adjustment"
        visible={showAdjust}
        onOk={() => setShowAdjust(false)}
        onCancel={() => {
          setShowAdjust(false);
          setAdjust(0);
        }}
        size="small"
        width={250}
        destroyOnClose={true}
      >
        <div className="d-flex flex-column justify-content-center w-100  align-items-center">
          <p>Enter Ajustment Amount</p>
          <InputNumber
            onChange={(e) => setAdjust(e)}
            allowClear
            defaultValue={adjust}
          />
        </div>
      </Modal>
      <OtherCostModalContent
        extraCostModal={extraCostModal}
        setExtraCostModal={setExtraCostModal}
        setExtraCostRemark={setExtraCostRemark}
        extraCost={extraCost}
        setExtraCost={setExtraCost}
        extraCostRemark={extraCostRemark}
      />
    </div>
  );
}

export default NewSale;
