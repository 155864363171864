import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    save: (state, data) => {
      state.value = data?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { save } = productSlice.actions;

export default productSlice.reducer;
