import { Typography } from "antd";
import { BASE_URL } from "utils/constants";

import styles from "./ProductComponent.module.scss";

function ProductComponent({ data, setProdSelect, showRetail = true }) {
  const { Text } = Typography;
  return (
    <div
      className={styles.ProductComponentWrapper}
      onClick={() => setProdSelect(data)}
    >
      <div>
        <img
          className={styles.imgWrap}
          src={BASE_URL + "/" + data?.imagekeys?.[0]}
          alt="product-img"
        />
      </div>
      <div className={styles.productName}>
        <Text ellipsis={true}>{data?.name}</Text>
      </div>
      <div className={styles.productPrice}>
        <span className={styles.currency}>AED&nbsp;</span>
        <span className={styles.value}>
          {showRetail
            ? data?.retailprice?.toFixed(2)
            : data?.wholesaleprice?.toFixed(2)}
        </span>
      </div>
    </div>
  );
}

export default ProductComponent;
