// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Product_ProductWrapper__5-H-G .Product_productImg__2kazt {\n  height: 50px;\n  width: 50px;\n  object-fit: contain; }\n\n.Product_ProductWrapper__5-H-G thead[class*=\"ant-table-thead\"] th {\n  background-color: #fff !important; }\n\n.Product_ProductWrapper__5-H-G .Product_heading__3zdMo {\n  color: #ae674c; }\n\n.Product_ProductWrapper__5-H-G .Product_btn__BxG5c {\n  background-color: #d02130;\n  border-color: #d02130;\n  border-radius: 5px;\n  display: flex;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://src/pages/Product/Product.module.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY;EACZ,WAAW;EACX,mBAAmB,EAAA;;AAJvB;EAOI,iCAAiC,EAAA;;AAPrC;EAWI,cAAc,EAAA;;AAXlB;EAeI,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,mBAAmB,EAAA","sourcesContent":[".ProductWrapper {\n  .productImg {\n    height: 50px;\n    width: 50px;\n    object-fit: contain;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n\n  .heading {\n    color: #ae674c;\n  }\n\n  .btn {\n    background-color: #d02130;\n    border-color: #d02130;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductWrapper": "Product_ProductWrapper__5-H-G",
	"productImg": "Product_productImg__2kazt",
	"heading": "Product_heading__3zdMo",
	"btn": "Product_btn__BxG5c"
};
export default ___CSS_LOADER_EXPORT___;
