import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import axios from "axios";
import EditProduct from "common/EditProduct";
import Topbar from "common/Topbar";
import { useState } from "react";
import { Print } from "react-easy-print";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./ProductDetail.module.scss";

function ProductDetail({ history }) {
  const product = history?.location?.state?.record;
  const [isModalVisible, setIsModalVisible] = useState(null);

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
        <Breadcrumb.Item>Products Details</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>{product?.name}</h3>
    </div>
  );
  const RightComponent = () => <div />;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ingredients",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Unit",
    //   dataIndex: "unit",
    //   key: "unit",
    // },

    {
      title: "Measure (g)",
      key: "qty",
      render: (data) => <span>{data?.qty}</span>,
    },
    {
      title: "Cost/Unit",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Sub Total",
      key: "subtotal",
      render: (data) => <span>{data?.subtotal?.toFixed(2)}</span>,
    },
  ];

  const data = product?.ingredients?.map((item, key) => ({
    key: key + 1,
    ...item,
  }));

  const [loading, setLoading] = useState(false);
  const handleDelete = (id) => {
    let config = {
      method: "delete",
      url: `${BASE_URL}/api/products/${id}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        history.push("/products");
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={styles.ProductDetailWrapper}>
      {/* ------------------ Print Content ------------------ */}
      <Print printOnly>
        <div className={styles.printWrap}>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <h6 style={{ color: "brown", marginBottom: 0 }}>
              Golden Ribbon Chocolates
            </h6>
            {/* <p className="mb-0">Al Muntazah Plaza, Al Qulayya,Sharjah</p>
            <p className="mb-0">Emirate: Sharjah, Contact: 067676335</p> */}
            <Divider className="mt-2" />
          </div>
          <Row className=" mb-0" align="middle">
            <Col span={16}>
              <Descriptions
                bordered
                title=""
                size={"small"}
                className="mb-2"
                layout={"vertical"}
              >
                <Descriptions.Item label="Product ID">
                  {product?.code || product?._id}
                </Descriptions.Item>
                <Descriptions.Item label="Product Name">
                  {product?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Category">
                  {product?.category}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {product?.description}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col
              span={8}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Avatar shape="square" size={120} src={product?.image} />
            </Col>

            {/* <Divider className="my-1" /> */}
          </Row>
          <Descriptions
            bordered
            title="Weight & Yields"
            size={"small"}
            layout="vertical"
            column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Total Weight">
              {product?.weight}
            </Descriptions.Item>
            <Descriptions.Item label="Weight per Piece(g)">
              {product?.pieceweight}
            </Descriptions.Item>
            <Descriptions.Item label="Yield (Pieces)">
              {product?.yieldpcs}
            </Descriptions.Item>
            <Descriptions.Item label="No. of items in Packet">
              {product?.yieldpkts}
            </Descriptions.Item>
          </Descriptions>

          <h6 className="mt-3">Raw Materials</h6>
          <Table
            columns={columns}
            bordered
            dataSource={data}
            className={styles.customTable}
            size="small"
            footer={() => (
              <div className="d-flex align-items-center justify-content-end">
                <h6 className="mb-0">
                  Total &nbsp;&nbsp; AED {product?.ingredientcost?.toFixed(2)}
                </h6>
                <div className="px-4" />
              </div>
            )}
            pagination={false}
          />
          <br />
          <Descriptions
            bordered
            title="Cost Details"
            size={"small"}
            layout="vertical"
            column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Overhead %">
              {product?.overhead}
            </Descriptions.Item>
            <Descriptions.Item label="Over Head Cost (AED)">
              {product?.overheadcost?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Other Cost (AED)">
              {product?.othercosts?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Total Cost">
              <p className={styles.total}>AED {product?.price}</p>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            bordered
            title=""
            layout="vertical"
            size={"small"}
            className="my-4"
            column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Unit Price">
              AED {product?.unitprice}
            </Descriptions.Item>
            <Descriptions.Item label="Packet Price">
              AED{" "}
              {(
                Number(product?.unitprice) * Number(product?.yieldpkts)
              )?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Proposed Whole Sale Price">
              AED {product?.wholesaleprice?.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Proposed Retail Price">
              AED {product?.retailprice}
            </Descriptions.Item>
          </Descriptions>
          <Row className="mt-5 pt-2">
            <Col span={8}>
              <h6 className="text-center">Chef</h6>
            </Col>
            <Col span={8}>
              <h6 className="text-center">Chief Accountant</h6>
            </Col>
            <Col span={8}>
              <h6 className="text-center">General Manager</h6>
            </Col>
          </Row>
        </div>
      </Print>
      {/* ------------------ Print Content ------------------ */}
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <Row className="m-3" justify="space-between" gutter={10}>
        <Col span={17} className="p-4 bg-white">
          {/* <h1>{product?.name}</h1> */}
          <p className={styles.price}>AED&nbsp;{product?.price}</p>
          <div>
            <Avatar shape="square" size={150} src={product?.image} />
          </div>
          <Divider className="my-3" />

          <h5>Product ID</h5>
          <p className={styles.description}>{product?.code || product?._id}</p>
          <h5>Category</h5>
          <p className={styles.description}>{product?.category}</p>
          <Divider className="my-3" />
          <h5>Description</h5>
          <p className={styles.description}>{product?.description}</p>
          <h5 className="mt-3">Raw Materials</h5>
          <Table
            columns={columns}
            dataSource={data}
            size="small"
            footer={() => (
              <div className="d-flex justify-content-end">
                <h6>
                  Total &nbsp;&nbsp; AED {product?.ingredientcost?.toFixed(2)}
                </h6>
                <div className="px-4" />
              </div>
            )}
            pagination={false}
          />
          <h5 className="mt-3">Weight</h5>
          <Row className="mt-1">
            <Col span={6}>
              <p className="mb-0">Total Weight</p>
              <p>{product?.weight || 0} </p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Weight Per Piece(g)</p>
              <p>{product?.pieceweight || 0} </p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Pieces per packet</p>
              <p>{product?.yieldpkts?.toFixed(2) || 0}</p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Yield Pieces</p>
              <p>{product?.yieldpcs?.toFixed(2)}</p>
            </Col>
          </Row>
          <h5 className="mt-3">Price Details</h5>
          <Row>
            <Col span={6}>
              <p className="mb-0">Over Head %</p>
              <p>{product?.overhead}</p>
            </Col>
            <Col span={6}>
              <p className="mb-0">OtherCost (AED)</p>
              <p>AED {product?.othercosts?.toFixed(2)}</p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Over Head Cost (AED)</p>
              <p>AED {product?.overheadcost?.toFixed(2)}</p>
            </Col>
            <Col span={6}>
              <p className={styles.total}>Total Cost (AED)</p>
              <p className={styles.total}>AED {product?.price}</p>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <p className="mb-0">Unit Price</p>
              <p>AED {product?.unitprice}</p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Packet Price</p>
              <p>
                AED{" "}
                {(
                  Number(product?.unitprice) * Number(product?.yieldpkts)
                )?.toFixed(2) || 0}
              </p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Whole Sale Price</p>
              <p>AED {product?.wholesaleprice?.toFixed(2)}</p>
            </Col>
            <Col span={6}>
              <p className="mb-0">Retail Price</p>
              <p>AED {product?.retailprice}</p>
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <div className={styles.card}>
            <h6>Action</h6>
            <Row gutter={10} justify="space-around" className="mt-2">
              <Button
                icon={<EditOutlined />}
                className="d-flex align-items-center justify-content-center"
                onClick={() => setIsModalVisible(product)}
              >
                Edit
              </Button>
              <Button
                icon={<DeleteOutlined />}
                className="d-flex align-items-center justify-content-center"
                onClick={() => {
                  Modal.confirm({
                    title: "Delete Product",
                    icon: <DeleteOutlined />,
                    content: "Are you sure you want to delete this product?",
                    okText: "Sure",
                    cancelText: "Cancel",
                    onOk: () => handleDelete(product?._id),
                  });
                }}
                loading={loading}
              >
                Delete
              </Button>
            </Row>
            <br />
            <h6>Status</h6>
            <Input
              value={product?.status ? "In Stock" : "Out of Stock"}
              disabled
            />
            <h6 className="mt-3">Print</h6>

            <Button
              icon={<PrinterOutlined />}
              className="d-flex w-100 text-danger align-items-center justify-content-center"
              onClick={() => window.print()}
            >
              Print Receipe Card
            </Button>
          </div>
        </Col>
      </Row>
      <Modal
        footer={null}
        title="Edit Product"
        visible={isModalVisible !== null}
        setIsModalVisible={setIsModalVisible}
        onCancel={() => setIsModalVisible(null)}
        isModalVisible={isModalVisible}
        destroyOnClose={true}
        width="1000px"
      >
        <EditProduct
          detail={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          getProducts={() => history.push("/products")}
        />
      </Modal>
    </div>
  );
}

export default ProductDetail;
