import { PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  // Checkbox,
  Col,
  // Dropdown,
  // Menu,
  message,
  Row,
  // Space,
  Table,
} from "antd";
import Search from "antd/lib/input/Search";
import axios from "axios";
import SaleReportDownloadModal from "common/SaleReportDownloadModal";
import Topbar from "common/Topbar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Sales.module.scss";

function Sales() {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Sales</Breadcrumb.Item>
        <Breadcrumb.Item>Invoice</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Invoice</h3>
    </div>
  );

  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => history.push("/new-sale")}
    >
      New Sale
    </Button>
  );
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);

  const getSales = () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${BASE_URL}/api/sales`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setResult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  useEffect(() => {
    getSales();
  }, []);

  const dataSource = result?.map((item) => {
    let product = "";
    item?.items?.forEach((element) => {
      product = `${product}${element?.name},`;
    });
    return {
      id: item?.invoicenumber,
      time: moment(new Date(item?.date)).format("DD/MM/YY"),
      customer: item?.user?.username,
      items: product,
      total: item?.nettotal,
      payment: item?.paymenttype,
      status: item?.status,
      invoicenumber: item?.invoicenumber,
      _id: item?._id,
      ordernumber: item?.ordernumber,
      // ...item,
    };
  });

  const getOrderById = (id) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/sales/${id}`,
      headers: {
        "x-auth-token": token(),
      },
    };

    axios(config)
      .then(function (response) {
        history.push("/invoice", { data: response.data.data });
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const columns = [
    // {
    //   title: () => <Checkbox checked disabled />,
    //   key: "checkbox",
    //   width: "5%",
    //   render: () => (
    //     <Space size="middle">
    //       <Checkbox />
    //     </Space>
    //   ),
    // },
    {
      title: "Invoice ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Order No.",
      dataIndex: "ordernumber",
      key: "ordernumber",
    },
    {
      title: "Date",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      ellipsis: true,
    },
    {
      title: "Total",
      key: "total",
      render: (data) => <span>{`AED ${data?.total?.toFixed(2)}`}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Receipt",
      key: "receipt",
      render: (data) => (
        <Button
          type="primary"
          onClick={() => getOrderById(data?.invoicenumber)}
        >
          Receipt
        </Button>
      ),
    },

    // {
    //   title: "",
    //   key: "action",
    //   width: "5%",
    //   render: () => (
    //     <Dropdown overlay={menu} trigger={["click"]}>
    //       <div className="w-100 d-flex align-items-center justify-content-center">
    //         <MoreOutlined />
    //       </div>
    //     </Dropdown>
    //   ),
    // },
  ];

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="0">
  //       <p>View</p>
  //     </Menu.Item>
  //     <Menu.Item key="1">
  //       <p>Delete</p>
  //     </Menu.Item>
  //   </Menu>
  // );
  const [showSale, setShow] = useState(false);
  return (
    <div className={styles.SalesWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="container pt-3">
        <Row className="mb-3" justify="space-between">
          <Col>
            <Search
              placeholder="Invoice ID"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(val) => getOrderById(val)}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={() => setShow(true)}>
              Download
            </Button>
          </Col>
        </Row>
        <Table dataSource={dataSource} columns={columns} loading={loading} />
      </div>
      <SaleReportDownloadModal show={showSale} setShow={setShow} />
    </div>
  );
}

export default Sales;
